<template>
  <v-container
    class="complete-user-data pa-0 text-center text-md-left align-center align-md-start"
    fluid
  >
    <v-container fluid>
      <h2>{{ $t("register.completeData.title") }}</h2>
      <h4 class="mb-10">{{ $t("register.completeData.text") }}</h4>

      <v-form ref="form" v-model="isValid" v-if="billingData">
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <!-- ragione sociale -->
            <v-text-field
              v-model="billingData.person.company"
              :label="$t('register.completeData.companyLabel')"
              :placeholder="$t('register.completeData.companyPlaceholder')"
              readonly
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3" xl="3">
            <!-- codice cliente -->
            <v-text-field
              v-model="billingData.person.codInt"
              :label="$t('register.completeData.customerCodeLabel')"
              :placeholder="$t('register.completeData.customerCodePlaceholder')"
              readonly
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <!-- partita iva -->
            <v-text-field
              v-model="billingData.person.vatCode"
              :label="$t('register.completeData.vatCodeLabel')"
              :placeholder="$t('register.completeData.vatCodePlaceholder')"
              readonly
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <!-- codice fiscale -->
            <v-text-field
              v-model="billingData.person.fiscalCode"
              :label="$t('register.completeData.fiscalCodeLabel')"
              :placeholder="$t('register.completeData.fiscalCodePlaceholder')"
              readonly
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-0">
            <!-- punto vendita di riferimento -->
            <v-select
              v-model="addressId"
              item-text="address.addressName"
              item-value="address.addressId"
              :label="$t('register.completeData.storeAddressLabel')"
              :placeholder="$t('register.completeData.storeAddressPlaceholder')"
              readonly
              outlined
              dense
              :items="warehouses"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <!-- pec -->
            <v-text-field
              v-model="billingData.person.emailCertified"
              :label="$t('register.completeData.pecLabel')"
              :placeholder="$t('register.completeData.pecPlaceholder')"
              :rules="emailPecRules"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <!-- codice interscambio -->
            <v-text-field
              v-model="billingData.person.vatSdiCode"
              :label="$t('register.completeData.sdiCodeLabel')"
              :placeholder="$t('register.completeData.sdiCodePlaceholder')"
              :rules="sdiRules"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-row
        no-gutters
        class="mt-10 mt-lg-5 mb-10 mb-md-16 d-flex justify-center justify-md-start"
      >
        <v-btn
          depressed
          color="primary"
          class="white--text"
          :disabled="!isValid"
          @click="next()"
        >
          {{ $t("register.completeData.next") }}
        </v-btn>
        <!-- <h5 class="error--text ml-4" v-if="error">
          {{ $t("register.cardCheck.yes.error") }}
        </h5> -->
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
// import { mapState } from "vuex";
import AddressService from "~/service/addressService";
import RegistrationService from "~/service/userService";
import DeliveryService from "~/service/deliveryService";
import { requiredValue } from "@/validator/validationRules";
import { req } from "../../validator/commons";
export default {
  props: ["billingData", "addressId"],
  data() {
    return {
      isValid: false,
      requiredValue: requiredValue,
      warehouses: [],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (this.billingData && !this.billingData.person.vatSdiCode) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (
            this.billingData &&
            !this.billingData.person.emailCertified
          ) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ]
    };
  },
  methods: {
    /**
     * caricamento della lista dei punti vendita
     */
    async loadWarehouses() {
      let that = this;
      let res = await AddressService.findWarehouse();
      if (res && res.warehouses) {
        that.warehouses = res.warehouses;
      }
    },
    /**
     * validazione dei dati del form e aggiornamento dei dati di billing,
     * poi caricamento degli indirizzi dell'utente prima di procedere
     */
    async next() {
      let that = this;
      try {
        this.error = null;
        this.loading = true;
        if (this.$refs.form.validate()) {
          let res = await RegistrationService.updateBillingAddress(
            this.billingData
          );
          this.loading = false;
          if (res) {
            this.getAddresses();
            // this.$emit("next", {
            //   step: 5,
            //   registrationCompleted: false,
            //   user: res
            // });
          } else {
            if (res.errors.length && res.errors[0].error) {
              that.error = res.errors[0].error;
            }
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    },

    /**
     * recupero gli indirizzi dell'utente che passerò come prop allo step successivo di navigazione
     */
    async getAddresses() {
      let that = this;
      try {
        this.error = null;
        this.loading = true;
        if (this.$refs.form.validate()) {
          let res = await DeliveryService.getAddressByDeliveryService(2);
          this.loading = false;
          if (res && res.addresses) {
            // ordino gli indirizzi mettendo per primo quello selezionato per spedizione
            let addresses = global.vm.lodash.orderBy(
              res.addresses,
              ["selected"],
              ["desc"]
            );
            this.$emit("next", {
              step: 5,
              registrationCompleted: false,
              addresses: addresses
            });
          } else {
            if (res.errors.length && res.errors[0].error) {
              that.error = res.errors[0].error;
            }
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.loadWarehouses();
  }
};
</script>
