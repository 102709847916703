<template>
  <v-container fluid class="register d-flex flex-column pa-0">
    <!-- INTESTAZIONE desktop -->
    <v-row
      justify="center"
      align="center"
      class="page-header category-title d-none d-sm-flex align-center"
    >
      <v-img
        v-if="!$vuetify.breakpoint.xs"
        dark
        src="/registration-header.jpg"
        height="170"
        class="elevation-0 rounded-0"
      >
        <div class="layer"></div>
        <h2>{{ $t("register.title") }}</h2>
      </v-img>
    </v-row>

    <!-- INTESTAZIONE mobile -->
    <v-row
      no-gutters
      justify="start"
      align="center"
      class="page-header d-flex d-sm-none pl-7"
    >
      <h2>{{ $t("register.title") }}</h2>
    </v-row>

    <!-- BREADCRUMBS TODO: -->
    <!-- <v-breadcrumbs
      class="hidden-sm-and-down"
      :items="breadcrumbs"
    ></v-breadcrumbs> -->

    <!-- STEPPER -->
    <v-container fluid class="pa-0">
      <v-stepper v-model="currentStep" :change="toTop()">
        <v-stepper-header
          class="ma-auto"
          v-show="currentStep !== 5 || !endSteps"
        >
          <template v-for="n in steps">
            <v-stepper-step
              :key="steps.indexOf(n)"
              :complete="currentStep > n"
              :step="steps.indexOf(n) + 1"
            >
            </v-stepper-step>

            <v-divider
              v-if="steps.indexOf(n) + 1 < steps.length"
              :key="`${steps.indexOf(n)}-divider`"
            ></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content class="pa-0" step="1">
            <!-- componente con due possibili form: 
              - verifica c.f./p.iva e numero tessera
              - compilazione dati per richiesta tessera
            -->
            <CardCheck @next="nextStep" @end="endPendingRegistration" />
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="2">
            <!-- inserimento user e password per clienti muniti di tessera -->
            <CreateOnlineAccount
              @next="nextStep"
              @prev="backToFirstStep"
              :registerData="navigationParams.user"
            />
            <!-- :registerData="navigationParams.user" -->
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="3">
            <!-- invio della email di verifica oppure messaggio di email verificata -->
            <EmailVerification
              @next="nextStep"
              :verified="navigationParams.verified"
              :email="navigationParams.email"
            />
          </v-stepper-content>

          <v-stepper-content step="4">
            <!-- inserimento dei dati per utente riconosciuto -->
            <CompleteUserData
              @next="nextStep"
              :billingData="navigationParams.user"
              :addressId="navigationParams.addressId"
            />
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="5" v-if="endSteps">
            <!-- chiusura registrazione dopo inserimento e update dati -->
            <RegistrationCompleted :pending="pending" />
          </v-stepper-content>
          <v-stepper-content v-else step="5">
            <!-- modifica indirizzi prima di chiusura -->
            <v-container
              fluid
              class="text-center text-sm-left align-center align-sm-start"
            >
              <h2>{{ $t("register.editAddresses.title") }}</h2>
              <h4 class="mb-10">{{ $t("register.editAddresses.text") }}</h4>
            </v-container>
            <AddressGrid
              class="edit-addresses"
              :addresses="navigationParams.addresses"
              :user="navigationParams.user"
              @refresh="refreshAddresses"
            ></AddressGrid>

            <v-row
              no-gutters
              class="btns justify-center justify-md-start mt-12 mb-5"
            >
              <v-btn
                depressed
                color="primary btn-next"
                @click="endRegistration()"
              >
                {{ $t("addresses.card.submit") }}
              </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-container>
</template>

<script>
import CustomMarketSuiteService from "@/service/marketsuiteCustomService";
import DeliveryService from "~/service/deliveryService";
import CardCheck from "@/components/registration/CardCheck.vue";
import CreateOnlineAccount from "@/components/registration/CreateOnlineAccount.vue";
import EmailVerification from "@/components/registration/EmailVerification.vue";
import CompleteUserData from "@/components/registration/CompleteUserData.vue";
import AddressGrid from "@/components/delivery/AddressGrid.vue";
import RegistrationCompleted from "@/components/registration/RegistrationCompleted.vue";
import { requiredValue, isEmail, isDate } from "@/validator/validationRules";
export default {
  components: {
    CardCheck,
    CreateOnlineAccount,
    EmailVerification,
    CompleteUserData,
    AddressGrid,
    RegistrationCompleted
  },
  data() {
    return {
      steps: [{ name: 1 }, { name: 2 }, { name: 3 }, { name: 4 }, { name: 5 }],
      currentStep: 1,
      // prova: {
      //   email: "prova@priva.ir",
      //   password="dewd",
      //   passwordConfirm="dewd"
      // },
      requiredRules: requiredValue,
      dateRules: [requiredValue("Digitare la data di nascita"), isDate()],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      errors: [],
      response: {},
      loading: false,
      navigationParams: {},
      endSteps: false,
      pending: false
    };
  },
  created() {
    if (this.$route.query.email && this.$route.query.token) {
      //TEST MODE
      // this.nextStep({
      //   step: 5,
      //   verified: true,
      //   email: this.$route.query.e_mail
      // });
      //TEST MODE
      this.doConfirm(this.$route.query.email, this.$route.query.token);
    }
  },
  computed: {},
  methods: {
    /**
     * recupero i parametri in queryString ed effettuo autologin dell'utente,
     * dopodiché navigo allo step 3 (utente confermato -> completamento dati -> edit indirizzi)
     */
    async doConfirm(email, controlCode, newEmail) {
      let that = this;
      try {
        this.error = null;
        this.loading = true;
        let res = await CustomMarketSuiteService.confirmRegistration(
          email,
          controlCode,
          newEmail
        );
        this.loading = false;
        if (res) {
          this.nextStep({ step: 3, verified: true, email: email });
        } else {
          if (res.errors.length && res.errors[0].error) {
            that.error = res.errors[0].error;
            that.$router.push({ name: "Home" });
          }
        }
      } catch (response) {
        this.response = response;
        this.$router.push({ name: "Home" });
      } finally {
        this.loading = false;
      }
    },
    toTop() {
      //TODO: verificare l'animazione su dispositivi
      this.$nextTick(() => {
        this.$vuetify.goTo(0);
      });
    },
    /**
     * reload degli indirizzi
     */
    async refreshAddresses() {
      let that = this;
      try {
        this.error = null;
        this.loading = true;
        let res = await DeliveryService.getAddressByDeliveryService(2);
        this.loading = false;
        if (res && res.addresses) {
          // ordino gli indirizzi mettendo per primo quello selezionato per spedizione
          this.currentAddresses = global.vm.lodash.orderBy(
            res.addresses,
            ["selected"],
            ["desc"]
          );
        } else {
          if (res.errors.length && res.errors[0].error) {
            that.error = res.errors[0].error;
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    },
    backToFirstStep() {
      this.navigationParams = {};
      this.currentStep = 1;
    },
    /**
     * gestisco la navigazione fra gli step, preparando una prop navigationParams
     * di volta in volta che sarà passata ai vari step in base alle esigenze
     */
    nextStep(params) {
      this.navigationParams = {};
      if (params) {
        this.navigationParams = params;
      }
      this.currentStep = params.step;
    },
    /**
     * navigazione al termine della registrazione:
     * valida per utenti con carta che hanno ricevuto link via email
     * e completato la compilazione dei dati
     */
    endRegistration() {
      this.endSteps = true;
    },
    /**
     * necessario per la visualizzazione del messaggio di chiusura di richiesta
     * di registrazione (messaggio mostrato agli utenti privi di carta che hanno
     * compilato il form allo step 1 -> opzione NO carta)
     */
    endPendingRegistration() {
      this.endSteps = true;
      this.currentStep = 5;
      this.pending = true;
    }
  },
  watch: {
    steps(val) {
      if (this.currentStep > val) {
        this.currentStep = val;
      }
    }
  }
};
</script>
