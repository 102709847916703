<template>
  <v-container class="card-check pa-0" fluid>
    <v-row justify="center" align="center" class="tab-switch px-4 px-sm-0">
      <div>
        <h2 class="mb-2 secondary--text text-center">
          {{ $t("register.cardCheck.switch-text") }}
        </h2>
        <v-row justify="center" no-gutters>
          <v-btn-toggle light v-model="tab">
            <v-btn class="mx-4" value="1">{{ $t("common.yes") }}</v-btn>
            <v-btn class="mx-4" value="2">{{ $t("common.no") }}</v-btn>
          </v-btn-toggle>
        </v-row>
      </div>
    </v-row>

    <!-- TAB 1 (utente ha la card) -->
    <v-container
      fluid
      class="tab-card text-center text-md-left align-center align-md-start"
      v-show="tab === '1'"
    >
      <h2>{{ $t("register.cardCheck.yes.title") }}</h2>
      <h4 class="mb-10">{{ $t("register.cardCheck.yes.mandatory") }}</h4>
      <v-img
        class="mb-10 mb-sm-5 mx-auto mx-md-0"
        width="196"
        :src="require('@/assets/card.svg')"
      />

      <v-form ref="cardform" v-model="isCardFormValid">
        <v-row class="d-flex">
          <v-col cols="12" lg="4">
            <!-- numero carta -->
            <v-text-field
              v-model="registerData.fidelityCard"
              :label="`${$t('register.cardCheck.yes.fidelityCard')} *`"
              :placeholder="$t('register.cardCheck.yes.fidelityCard')"
              :rules="[requiredRules()]"
              required
              outlined
              dense
            ></v-text-field>
            <h5 class="info-label text-left">
              {{ $t("register.cardCheck.yes.info") }}
            </h5>
          </v-col>
          <v-col cols="12" lg="4">
            <!-- codice fiscale/partita iva -->
            <v-text-field
              v-model="registerData.person.fiscalCode"
              :rules="cfIvaRules"
              :label="`${$t('register.cardCheck.yes.fiscalCode')} *`"
              :placeholder="$t('register.cardCheck.yes.fiscalCode')"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <div class="legal-agreement mt-3">
        <i18n path="register.cardCheck.yes.privacy" tag="span">
          <template v-slot:link>
            <a
              role="button"
              class="go-to-privacy"
              @click.stop.prevent="goToPrivacy"
              @mousedown.stop
              >{{ $t("register.cardCheck.yes.privacyLink") }}</a
            >
            <!-- <router-link
              :to="{
                name: 'Page',
                params: { pageName: 'privacy-policy' }
              }"
            >
              {{ $t("register.cardCheck.yes.privacyLink") }}
            </router-link> -->
          </template>
        </i18n>
        <v-checkbox light class="mt-1 mb-8" hide-details v-model="checked">
          <template v-slot:label>
            {{ $t("register.cardCheck.yes.confirm") }}
          </template>
        </v-checkbox>
      </div>

      <v-row no-gutters class="d-flex justify-center justify-md-start">
        <v-btn
          color="primary"
          class="white--text"
          depressed
          :disabled="!isCardFormValid || !checked"
          @click="submitCardData()"
        >
          {{ $t("register.cardCheck.yes.submit") }}
        </v-btn>
        <h5 class="error--text ml-4 font-weight-bold" v-if="error">
          {{ $t("register.cardCheck.yes.error") }}
        </h5>
      </v-row>
    </v-container>

    <!-------------------------------------------------------------------->

    <!-- TAB 2 (utente non ha la card) -->
    <v-container
      fluid
      class="tab-card text-center text-md-left align-center align-md-start"
      v-show="tab === '2'"
    >
      <h2>{{ $t("register.cardCheck.no.title") }}</h2>
      <h4 class="mb-10">{{ $t("register.cardCheck.no.mandatory") }}</h4>

      <v-form ref="registerform" v-model="isRegisterFormValid">
        <v-row>
          <v-col cols="12" lg="12" class="pb-0">
            <!-- punto vendita di riferimento -->
            <v-select
              v-model="registerData.defaultStoreAddress.addressId"
              item-text="address.addressName"
              item-value="address.addressId"
              :label="`${$t('register.cardCheck.no.puntoVendita')} *`"
              :placeholder="$t('register.cardCheck.no.puntoVendita')"
              :rules="[requiredValue('Punto vendita obbligatorio')]"
              required
              outlined
              dense
              :items="warehouses"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2 mb-6 mb-sm-0">
          <h5 class="text-left">{{ $t("register.cardCheck.no.warning") }}</h5>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12" lg="6">
            <!-- partita iva -->
            <v-text-field
              v-model="registerData.person.vatCode"
              :rules="vatCodeRulesNoCard"
              :label="`${$t('register.cardCheck.no.vatCode')} *`"
              :placeholder="$t('register.cardCheck.no.vatCode')"
              @blur="$refs.registerform.validate()"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <!-- codice fiscale -->
            <v-text-field
              v-model="registerData.person.fiscalCode"
              :label="$t('register.cardCheck.no.fiscalCode')"
              :placeholder="$t('register.cardCheck.no.fiscalCode')"
              :rules="fiscalCodeRulesNoCard"
              @blur="$refs.registerform.validate()"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="12" class="pb-0">
            <!-- tipo di attività -->
            <v-select
              v-model="registerData.person.industryId"
              item-text="text"
              item-value="value"
              :label="`${$t('register.cardCheck.no.businessType')} *`"
              :placeholder="$t('register.cardCheck.no.businessType')"
              :rules="[requiredRules()]"
              required
              outlined
              dense
              :items="businessTypes"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="6" class="pb-0">
            <!-- numero di cellulare -->
            <v-text-field
              v-model="registerData.phone"
              :label="`${$t('register.cardCheck.no.mobilePhone')} *`"
              :placeholder="$t('register.cardCheck.no.mobilePhone')"
              :rules="[requiredRules()]"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <h5 class="mb-14 text-left">{{ $t("register.cardCheck.no.info") }}</h5>

      <div class="legal-agreement mt-3">
        <i18n path="register.cardCheck.no.privacy" tag="span">
          <template v-slot:link>
            <a
              role="button"
              class="go-to-privacy"
              @click.stop.prevent="goToPrivacy"
              @mousedown.stop
              >{{ $t("register.cardCheck.no.privacyLink") }}</a
            >
            <!-- <router-link
              :to="{
                name: 'Page',
                params: { pageName: 'privacy-policy' }
              }"
            >
              {{ $t("register.cardCheck.no.privacyLink") }}
            </router-link> -->
          </template>
        </i18n>
        <v-checkbox
          class="legal-agreement mt-1 mb-8 "
          light
          hide-details
          v-model="checked"
        >
          <template v-slot:label>
            {{ $t("register.cardCheck.no.confirm") }}
          </template>
        </v-checkbox>
      </div>
      <v-row no-gutters class="justify-center justify-md-start">
        <v-btn
          color="primary"
          class="white--text"
          depressed
          :disabled="!isRegisterFormValid || !checked"
          @click="submitRegistrationForm()"
        >
          {{ $t("register.cardCheck.no.submit") }}
        </v-btn>
      </v-row>
    </v-container>

    <SupportBar v-if="tab === '1'" />
    <FaqBar v-if="tab === '2'" />
  </v-container>
</template>
<script>
import page from "~/mixins/page";
import { requiredValue, isCfIva } from "@/validator/validationRules";
import CustomMarketSuiteService from "@/service/marketsuiteCustomService";
import AddressService from "~/service/addressService";
import BusinessTypes from "@/components/registration/json/businessTypes.json";
import SupportBar from "@/components/registration/SupportBar";
import FaqBar from "@/components/registration/FaqBar";
export default {
  components: {
    SupportBar,
    FaqBar
  },
  mixins: [page],
  data() {
    return {
      tab: "1",
      isCardFormValid: false,
      isRegisterFormValid: false,
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      checked: false,
      warehouses: [],
      businessTypes: BusinessTypes,
      registerData: {
        fidelityCard: null,
        // fidelityCard: "736000000381",
        defaultStoreAddress: {
          addressId: null
        },
        phone: null,
        person: {
          fiscalCode: null,
          // fiscalCode: "02536950013",
          vatCode: null,
          industryId: null
        }
      },
      response: {},
      cfIvaRules: [requiredValue(), isCfIva()],
      vatCodeRulesNoCard: [
        v =>
          !!(v || this.registerData.person.fiscalCode) ||
          "Codice Fiscale o Iva obbligatori",
        isCfIva()
      ],
      fiscalCodeRulesNoCard: [
        v =>
          !!(v || this.registerData.person.vatCode) ||
          "Iva o Codice Fiscale obbligatori",
        isCfIva()
      ],
      error: null
    };
  },
  methods: {
    goToPrivacy() {
      if (this.$platform_is_cordova) {
        window.cordova.InAppBrowser.open("/page/privacy-policy", "_system");
      } else {
        window.open("/page/privacy-policy", "_blank");
      }
    },
    /**
     * registrazione e conseguente invio di link via email
     * per utenti con carta
     */
    async submitCardData() {
      try {
        this.error = null;
        this.loading = true;
        if (this.$refs.cardform.validate()) {
          let res = await CustomMarketSuiteService.checkCard(this.registerData);
          console.log(res);
          this.loading = false;
          if (res.response && res.response.status === 0) {
            this.$emit("next", { step: 2, user: res.data.user });
          } else {
            if (res.errors) {
              global.EventBus.$emit("error", {
                error: res.errors[0].error,
                message: res.errors[0].error
              });
              this.error = true;
            }
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    },

    /**
     * richiesta di registrazione per gli utenti sprovvisti di carta
     */
    async submitRegistrationForm() {
      try {
        this.error = null;
        this.loading = true;
        if (this.$refs.registerform.validate()) {
          let res = await CustomMarketSuiteService.registrationUser(
            this.registerData
          );
          console.log(res);
          this.loading = false;
          if (res.response && res.response.status === 0) {
            this.$emit("end");
          } else {
            if (res.errors) {
              global.EventBus.$emit("error", {
                error: res.errors[0].error,
                message: res.errors[0].error
              });
            }
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    },

    /**
     * caricamento della lista dei punti vendita
     */
    async loadWarehouses() {
      let that = this;
      let res = await AddressService.findWarehouse();
      if (res && res.warehouses) {
        that.warehouses = res.warehouses;
      }
    }
  },
  created() {
    this.loadWarehouses();
  }
};
</script>
