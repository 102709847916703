<template>
  <v-container
    fluid
    class="create-account text-center text-sm-left align-center align-sm-start"
  >
    <h2>{{ $t("register.createOnlineAccount.title") }}</h2>
    <h4 class="mb-8 mb-sm-10 px-2 px-sm-0">
      {{ $t("register.createOnlineAccount.mandatory") }}
    </h4>

    <v-form ref="form" v-model="isValid" v-if="registerData">
      <v-row>
        <v-col cols="12" lg="6" class="pb-0">
          <!-- email-->
          <v-text-field
            v-model="registerData.email"
            :rules="emailRules"
            :label="`${$t('register.createOnlineAccount.usernameLabel')} *`"
            :placeholder="
              $t('register.createOnlineAccount.usernamePlaceholder')
            "
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" class="pb-5 pb-lg-0 pt-0 pt-lg-4">
          <h5 class="text-left">
            {{ $t("register.createOnlineAccount.usernameWarning") }}
          </h5>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" lg="6">
          <!-- password -->
          <v-text-field
            v-model="registerData.password"
            :label="`${$t('register.createOnlineAccount.passwordLabel')} *`"
            :placeholder="
              $t('register.createOnlineAccount.passwordPlaceholder')
            "
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <!-- conferma password -->
          <v-text-field
            v-model="registerData.passwordConfirm"
            :label="
              `${$t('register.createOnlineAccount.confirmPasswordLabel')} *`
            "
            :placeholder="
              $t('register.createOnlineAccount.confirmPasswordPlaceholder')
            "
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirm"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-container class="pt-0 legal-agreement">
      <v-row no-gutters>
        <v-checkbox
          light
          hide-details
          v-model="personInfos[1].value"
          :value="personInfos[1].personInfoTypeId"
        >
          <template v-slot:label>
            <span>
              {{
                $t(
                  "register.createOnlineAccount.privacy.secondPrivacyDescInitial"
                )
              }}
              <a @click.stop.prevent="goToSalesConditions()">{{
                $t(
                  "register.createOnlineAccount.privacy.secondPrivacyDescFinal"
                )
              }}</a>
            </span>
          </template>
        </v-checkbox>
      </v-row>

      <v-row no-gutters>
        <v-checkbox
          light
          hide-details
          v-model="personInfos[0].value"
          :value="personInfos[0].personInfoTypeId"
        >
          <template v-slot:label>
            <i18n
              path="register.createOnlineAccount.privacy.firstPrivacyDesc"
              tag="span"
            >
              <template v-slot:br><br /></template>
              <template v-slot:link>
                <a
                  role="button"
                  class="go-to-privacy"
                  @click.stop.prevent="goToPrivacy"
                  @mousedown.stop
                  >{{
                    $t(
                      "register.createOnlineAccount.privacy.firstPrivacyDescLink"
                    )
                  }}</a
                >
                <!-- <router-link
                  :to="{
                    name: 'Page',
                    params: { pageName: 'privacy-policy-acquisto' }
                  }"
                >
                  {{
                    $t(
                      "register.createOnlineAccount.privacy.firstPrivacyDescLink"
                    )
                  }}
                </router-link> -->
              </template>
            </i18n>
          </template>
        </v-checkbox>
      </v-row>

      <v-row no-gutters class="pl-8">
        <v-radio-group required light v-model="personInfos[2].value" row>
          <div></div>
          <template v-slot:label>
            <span
              v-html="
                $t('register.createOnlineAccount.privacy.thirdPrivacyDesc')
              "
            ></span
          ></template>
          <v-radio :label="$t('common.yes')" :value="true"></v-radio>
          <v-radio :label="$t('common.no')" :value="false"></v-radio>
        </v-radio-group>
      </v-row>

      <v-row no-gutters class="pl-8">
        <v-radio-group required light v-model="personInfos[3].value" row>
          <template v-slot:label>
            <span
              v-html="
                $t('register.createOnlineAccount.privacy.fourthPrivacyDesc')
              "
            ></span
          ></template>
          <v-radio :label="$t('common.yes')" :value="true"></v-radio>
          <v-radio :label="$t('common.no')" :value="false"></v-radio>
        </v-radio-group>
      </v-row>
    </v-container>
    <v-row
      no-gutters
      class="pt-4 pt-md-10 pb-16 pb-md-0 d-flex justify-center justify-lg-start"
    >
      <v-col cols="6">
        <v-btn
          outlined
          color="white"
          class="primary--text"
          depressed
          @click="goBack()"
        >
          INDIETRO
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="primary"
          class="white--text"
          depressed
          :disabled="
            !isValid ||
              personInfos[0].value != personInfos[0].personInfoTypeId ||
              personInfos[1].value != personInfos[1].personInfoTypeId ||
              personInfos[2].value == null ||
              personInfos[3].value == null
          "
          @click="submit()"
        >
          {{ $t("register.createOnlineAccount.submit") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "@/validator/validationRules";
import CustomMarketSuiteService from "@/service/marketsuiteCustomService";
import analyticsService from "@/commons/service/analyticsService";
export default {
  props: ["registerData"],
  data() {
    return {
      requiredValue: requiredValue,
      requiredRules: [requiredValue()],
      personInfos: [
        {
          personInfoTypeId: 1,
          value: false
        },
        {
          personInfoTypeId: 2,
          value: false
        },
        {
          personInfoTypeId: 3,
          value: null
        },
        {
          personInfoTypeId: 4,
          value: null
        }
      ],
      showPassword: false,
      isValid: true,
      lazy: false,
      response: {},
      error: false,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      passwordConfirm: [
        v => !!v || "Confermare la password",
        v => v === this.registerData.password || "Le password non coincidono"
      ]
    };
  },
  methods: {
    goToPrivacy() {
      if (this.$platform_is_cordova) {
        window.cordova.InAppBrowser.open(
          "/page/privacy-policy-acquisto",
          "_system"
        );
      } else {
        window.open("/page/privacy-policy-acquisto", "_blank");
      }
    },
    goBack() {
      this.$emit("prev");
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    goToSalesConditions() {
      window.open(
        "/page/condizioni-generali-di-acquisto-e-pagamento/",
        "_blank"
      );
    },
    // TODO: da verificare la mappatura dei valori di privacy da inviare
    async submit() {
      let that = this;
      let data = this.registerData;
      data.person.personInfos = this.personInfos;
      try {
        this.error = false;
        this.loading = true;
        if (that.$refs.form.validate()) {
          let res = await CustomMarketSuiteService.registerUserWithCard(
            this.registerData
          );
          console.log(res);
          this.loading = false;
          if (res && !res.errors) {
            analyticsService.signUp("registration_form");
            this.$emit("next", { step: 3, verified: false, email: res.email });
          } else {
            if (res.errors) {
              this.error = true;
            }
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
