<template>
  <v-row class="support-bar d-flex justify-center align-center">
    <div class="d-flex align-center flex-column flex-md-row py-6 py-md-11">
      <!-- immagine desktop -->
      <v-img
        eager
        :src="require('@/assets/footer/help1.svg')"
        class="d-none d-md-flex mr-8"
      ></v-img>
      <!-- immagine mobile -->
      <v-img
        eager
        :src="require('@/assets/footer/help1-small.svg')"
        class="small d-flex d-md-none mb-8"
      ></v-img>
      <div class="d-flex flex-column justify-center align-center">
        <h2>{{ $t("footer.support.title") }}</h2>
        <h3>{{ $t("footer.support.text") }}</h3>
        <v-btn
          :disabled="!checked"
          text
          color="primary"
          to="/page/contatti"
          class="mt-2"
          >{{ $t("footer.support.button") }}</v-btn
        >
        <!-- TODO: pulsante attivato se checked, da confermare -->
        <v-checkbox
          dark
          class="legal-agreement legal-agreement-white mt-5 px-8 px-md-0"
          hide-details
          v-model="checked"
        >
          <template v-slot:label>
            <i18n path="footer.support.privacy" tag="span">
              <template v-slot:link
                ><router-link
                  :to="{
                    name: 'Page',
                    params: { pageName: 'privacy-policy' }
                  }"
                >
                  {{ $t("footer.support.privacyLink") }}
                </router-link>
              </template>
            </i18n>
          </template>
        </v-checkbox>
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      checked: false
    };
  },
  methods: {
    goToPrivacy() {
      window.open("/page/privacy-policy", "_blank");
    }
  }
};
</script>
